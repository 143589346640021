.search-box {
  border: none;
  outline: none;
  padding: 10px;
  width: 150px;
  line-height: 30px;

  background-color: #1f2a40;

  margin-bottom: 30px;
}
