.map-container {
  height: 400px;
}

.vechile-section {
  display: flex;
  flex-direction: column;
}

.filtering {
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}
.filtering > h4 {
  text-align: center;
  font-size: 2rem;
  margin-top: 0.5rem;
}
.searchBars {
  display: flex;
  margin: auto;
}

.vechile-page {
  width: 72vw;
}
.vechiles {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.vechile-card {
  display: flex;
  height: fit-content;
  margin: 0.5rem;
  flex-direction: column;
  width: 20rem;
  border: 2px solid #a1a4ab;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
  align-items: center;
}

.vechile-location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #e8eae9;
  margin: 1rem;
}
.vechile-card > button {
  border: none;
  outline: none;
  padding: 0.7rem;
  border-radius: 1rem;
  color: #151632;
  cursor: pointer;
}
.addBtn {
  border: none;
  outline: none;
  /* padding: 0rem 0.7rem; */
  /* padding: 0rem 0.7rem; */
  border-radius: 0.5rem;
  max-height: 2.5rem;
  background: transparent;
  cursor: pointer;
}
.vechile-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Loading text styles */
.loading-text p {
  color: #fff; /* Adjust text color */
  font-size: 20px; /* Adjust font size */
  margin-bottom: 10px; /* Add margin */
}

.centered-loader {
  text-align: center;
}
